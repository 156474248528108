import {Box, Text} from "@chakra-ui/react";

export const TestingRibbon = ({text = "TESTING"}) => {
    return (
        <Box
            position="fixed"
            top={["100","100","130"]}
            left="-10"
            width={["200px","200px","250px"]}
            bg="red.500"
            color="white"
            textAlign="center"
            transform="rotate(-45deg)"
            transformOrigin="top left"
            zIndex={1000}
            shadow="xl"
        >
            <Text fontWeight="bold" fontSize={22}>
                {text}
            </Text>
        </Box>
    );
}